@import "css/colors.scss";
@import "css/theme.scss";

$border-radius: 4px;
$trim-handle-width: 30px;
$trim-handle-height: 30px;
$trim-handle-border: 3px;
$stripe-size: 10px;
$hover-scale: 1.25;

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Force the browser to paint separately to avoid composite cost with waveform */
  transform: translateZ(0);
}

.selector {
  cursor: pointer;
}

.trimBackground {
  cursor: pointer;
  touch-action: none;
}

.trimBackgroundMask {
  opacity: 0.5;

  @include theme {
    border: 1px solid getVar("error_active_color");
    background: repeating-linear-gradient(
                    45deg,
                    getVar("warning_color"),
                    getVar("warning_color"), $stripe-size,
                    getVar("warning_active_color") $stripe-size,
                    getVar("warning_active_color") calc(2 * #{$stripe-size})
    );
  }
}

.selectionBackground {
  opacity: 0.5;

  @include theme {
    background: getVar("primary_color")
  }
}

.startTrimBackground .trimBackgroundMask {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.endTrimBackground .trimBackgroundMask {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.trimLine {
  position: absolute;
  top: 0;
  width: 0px;
  height: 100%;
  @include theme {
    border: 1px solid getVar("warning_active_color");
  }
}

.selector .trimLine {
  @include theme {
    border: 1px solid getVar("primary_color");
  }
}

.playheadContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.playhead {
  /*
      Even though playhead is just a line, it is 100% width (the width of the waveform)
      so that we can use transform: translateX() using percentages.
  */
  width: 100%;
  height: 100%;

  border-top: none;
  border-bottom: none;
  border-right: none;

  @include theme {
    border-left: 1px solid getVar("primary_color");
  }
}

.rightHandle {
  transform: scaleX(-1);
}

.selector .leftHandle {
  left: -1px
}

.selector .rightHandle {
  right: -1px
}

.trimmer .leftHandle {
  right: -1px
}

.trimmer .rightHandle {
  left: -1px
}

.trimHandle {
  position: absolute;
  width: $trim-handle-width;
  height: $trim-handle-height;
  right: 0;
  user-select: none;
}

.trimmer .trimHandle {
  filter: hue-rotate(150deg);
}

.trimHandle img {
  position: absolute;
  width: $trim-handle-width;
  height: $trim-handle-height;
  left: calc(#{$trim-handle-border} * 1.5);

  /* Make sure image dragging isn't triggered */
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none; /* Autoprefixer doesn't seem to work for this */

  transition: 0.2s;
}

.topTrimHandle {
  top: calc(-#{$trim-handle-height} + #{$trim-handle-border})
}

.bottomTrimHandle {
  bottom: calc(-#{$trim-handle-height} + #{$trim-handle-border});
}

.topTrimHandle img {
  transform: scaleY(-1);
}
