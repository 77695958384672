@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.frameWrapper {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.frame {
  border-radius: 0.5rem;
  animation-name: flash;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  /* Leave at 0 opacity after animation */

  @include theme {
    //增加主色的透明度
    background: getVar("primary_box_shadow_color");
    border: 2px solid getVar("primary_color");
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
