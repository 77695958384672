@import "css/units.scss";
@import "css/colors.scss";
@import "css/theme";

.spriteSelectorItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  border-width: 2px;
  border-style: solid;
  border-radius: $space;

  text-align: center;
  cursor: pointer;

  user-select: none;

  @include theme {
    color: getVar("text_secondary_color");
    border-color: getVar("border_color");
  }
}

.spriteSelectorItem.isSelected {
  background: $ui-white;
  @include theme {
    border: 2px solid getVar("primary_color") !important;
    box-shadow: 0px 0px 0px 4px getVar("primary_box_shadow_color");
  }
}

.spriteSelectorItem:hover {
  background: $ui-white;
  @include theme {
    border: 2px solid getVar("primary_border_hover_color");
  }


}

.spriteSelectorItem:hover .spriteImage,
.isSelected .spriteImage {
  filter: drop-shadow(0px 0px 2px $ui-black-transparent);
}

/* Outer/Inner chicanery is to prevent layouts when sprite image changes */
.spriteImageOuter {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
}

.spriteImageInner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spriteImage {
  user-select: none;
  pointer-events: none;
  max-width: 32px;
  max-height: 32px;
}

.spriteInfo {
  padding: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  font-size: 0.625rem;
  user-select: none;

  @include theme {
    color: getVar("text_secondary_color")
  }
}

.spriteName,
.spriteDetails {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.spriteDetails {
  margin-top: 0.125rem;
  font-size: 0.5rem;
}

.isSelected .spriteInfo {
  @include theme {
    background: getVar("primary_color");
    color: getVar("invert_text_color");
  }
}

.deleteButton {
  position: absolute;
  top: -.625rem;
  z-index: auto;
}

[dir="ltr"] .deleteButton {
  right: -.625rem;
}

[dir="rtl"] .deleteButton {
  left: -.625rem;
}

.number {
  position: absolute;
  top: 0.15rem;
  font-size: 0.625rem;
  font-weight: bold;
  z-index: 2;
}

[dir="ltr"] .number {
  left: 0.15rem;
}

[dir="rtl"] .number {
  right: 0.15rem;
}
