@import "css/units.scss";
@import "css/colors.scss";
@import "css/theme.scss";

.questionContainer {
  margin: $space;

  border-radius: $space;
  border-width: 2px;
  padding: 1rem;
  background: white;

  @include theme {
    border: 1px solid getVar("text_quaternary_color");
  }
}

.questionLabel {
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text-primary;
  padding-bottom: $space;
}

.questionInput {
  display: flex; /* Keeps the input from going outside this container */
  position: relative;
}

.questionSubmitButton {
  position: absolute;
  top: calc(#{$space} / 2);

  width: calc(2rem - #{$space});
  height: calc(2rem - #{$space});

  border: none;
  border-radius: 100%;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  @include theme {
    background-color: getVar("primary_color");
  }
}

[dir="ltr"] .questionSubmitButton {
  right: calc(#{$space} / 2);
}

[dir="rtl"] .questionSubmitButton {
  left: calc(#{$space} / 2);
}

/* Input overrides: width, font-weight, focus outline and padding */
.questionInput > input {
  width: 100%;
  font-weight: normal;
}

[dir="ltr"] .questionInput > input {
  padding: 0 2rem 0 .75rem; /* To make room for the submit button */
}

[dir="rtl"] .questionInput > input {
  padding: 0 .75rem 0 2rem; /* To make room for the submit button */
}

.questionInput > input:focus {
  @include theme{
    box-shadow: 0 0 0 3px getVar("primary_active_color");;
  }
}

.questionSubmitButtonIcon {
  width: calc(2rem - #{$space});
  height: calc(2rem - #{$space});
  //position: relative;
  //right: -7px;
  //left: -7px;
}
