@import "css/colors.scss";
@import "css/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  border-radius: 0.5rem;

  @include theme {
    color: getVar("primary_color")
  }
}

.container + .container {
  //margin-top: 1.25rem;
}

.title {
  margin-top: 0.5rem;
  text-align: center;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.container:active {
  @include theme {
    background-color: getVar("primary_light_transparent");
  }
  //background-color: $motion-light-transparent;
}
