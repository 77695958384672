.tableWrapper {
    position: relative;
    overflow: hidden;
    color: #AAAAAA;
    font-size: 12px;
    font-weight: normal;
    background-color: #fff;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table td,
.table th {
    padding: 8px 16px;
    text-align: center;
    overflow: hidden;
}

.table th {
    background-color: #fff;
}

.border td,
.border th {
    border: 1px solid #ddd;
}

.td {
    vertical-align: middle;
}

.stickyHeader {
    position: absolute;
    top: 0;
    background-color: #fff;
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #E1E1E1;

    /* 确保表头在上面 */
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
            box-sizing: border-box;
        }
    }
}

.selectedRow {
    background-color: #e6f7ff;
    cursor: pointer;
    color: #6C6C6C;
}

.selectedRow:hover {
    background-color: #bae7ff;
}

.tableBody {
    margin-top: 38px;
    display: block;
    height: 100%;
    overflow-y: auto;
    /* 使表体部分可以滚动 */
}

.tableBody tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    /* 强制表格宽度固定 */
    td {
        box-sizing: border-box;
    }
}
