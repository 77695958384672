@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme";

/* wrapper to allow for touch slop if we decide to add it */
.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.15s ease-out;
}

.deleteButtonVisible {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;  /* Mask the icon animation */
  width: 1.75rem;
  height: 1.75rem;

  border-radius: 50%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  user-select: none;
  cursor: pointer;
  transition: all 0.15s ease-out;

  @include theme{
    box-shadow: 0px 0px 0px 2px getVar("primary_box_shadow_color");
    background-color: getVar("primary_color");
    color:getVar("invert_text_color")
  }
}

.deleteIcon {
    position: relative;
    margin: 0.25rem;
    user-select: none;
    transform-origin: 50%;
}
