@import "css/theme.scss";

$ui-black-transparent: hsla(0, 0%, 0%, 0.15);
$motion-primary: hsla(215, 100%, 65%, 1);
$ui-white-dim: hsla(0, 100%, 100%, 0.75);
$text-primary: hsla(225, 15%, 40%, 1); /* #575E75 */
$text-primary-transparent: hsla(225, 15%, 40%, 0.75);
$border-radius: 4px;


.editorContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

[dir="rtl"] .rowReverse {
  flex-direction: row-reverse;
}

.row + .row {
  margin-top: 16px;
}

.inputGroup {
  display: flex;
  flex-direction: row;
}

[dir="ltr"] .inputGroup + .inputGroup {
  margin-left: 16px;
}

[dir="rtl"] .inputGroup + .inputGroup {
  margin-right: 16px;
}

[dir="ltr"] .inputGroup {
  padding-right: 16px;
  //border-right: 1px dashed $ui-black-transparent;
  @include theme {
    border-right: 1px solid getVar("text_quaternary_color");
  }
}

[dir="rtl"] .inputGroup {
  padding-left: 16px;
  //border-left: 1px dashed $ui-black-transparent;
  @include theme {
    border-left: 1px solid getVar("text_quaternary_color");
  }
}

[dir="rtl"] .rowReverse > .inputGroup {
  padding-left: 0;
  padding-right: 16px;
  border-left: none;
  //border-right: 1px dashed $ui-black-transparent;
  @include theme {
    border-right: 1px solid getVar("text_quaternary_color");
  }
}

.waveformContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  position: relative;

  background: hsla(300, 53%, 60%, 0.15);
  //border: 1px solid $ui-black-transparent;

  border-radius: 5px;

  margin-top: 20px;
  margin-bottom: 20px;
  
  @include theme {
    border: 1px solid getVar("text_quaternary_color");
  }

}

$border-radius: 5px;

.button {
  height: 2rem;
  padding: 0.35rem;
  outline: none;
  background: white;
  border-radius: $border-radius;
  //border: 1px solid $ui-black-transparent;

  cursor: pointer;
  font-size: 0.85rem;
  transition: 0.2s;
  user-select: none;
  margin: 0;

  @include theme {
    border: 1px solid getVar("text_quaternary_color");
  }
}

.button > img {
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
  min-width: 1.25rem;
}

.roundButton {
  height: 3rem;
  width: 3rem;
  outline: none;
  //background: $motion-primary;

  border-radius: 100%;
  border: 4px solid $ui-white-dim;
  cursor: pointer;
  padding: 0.75rem;
  user-select: none;

  @include theme {
    background: getVar("primary_color");
  }
}

.roundButton > img {
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
  /*min-width: 1.5rem;*/
}

[dir="rtl"] .undoIcon, [dir="rtl"] .redoIcon {
  transform: scaleX(-1);
}

.trimButton {
  display: flex;
  align-items: center;
  color: $text-primary;
  font-size: 0.625rem;
  user-select: none;

}

[dir="ltr"] .trimButton {
  margin-left: 1rem;
}

[dir="rtl"] .trimButton {
  margin-right: 1rem;
}

.trimButton > img {
  width: 1.25rem;
}

.effectButton {
  flex-basis: 60px;
  color: $text-primary;
  font-size: 0.625rem;
  user-select: none;
  padding: 0.25rem 0;
}

.effectButton + .effectButton {
  margin: 0;
}

.effectButton img {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: -0.375rem;
}

.toolButton {
  flex-basis: 60px;
  color: $text-primary;
  font-size: 0.625rem;
  user-select: none;
  padding: 0.25rem 0;
}

.toolButton + .toolButton {
  margin: 0;
}

.toolButton img {
  width: 4rem;
  height: 1.5rem;
  margin-bottom: -0.375rem;
}

[dir="rtl"] .flipInRtl img {
  transform: scaleX(-1);
}

[dir="ltr"] .buttonGroup {
  margin-left: 1rem;
}

[dir="rtl"] .buttonGroup {
  margin-right: 1rem;
}

.buttonGroup {
  display: flex;
}

.buttonGroup .button {
  border-radius: 0;
}

[dir="ltr"] .buttonGroup .button {
  border-left: none;
}

[dir="rtl"] .buttonGroup .button {
  border-right: none;
}

[dir="ltr"] .buttonGroup .button:last-of-type {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

[dir="ltr"] .buttonGroup .button:first-of-type {
  border-left: 1px solid $ui-black-transparent;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

[dir="rtl"] .buttonGroup .button:last-of-type {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

[dir="rtl"] .buttonGroup .button:first-of-type {
  border-right: 1px solid $ui-black-transparent;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.button:disabled > img {
  opacity: 0.25;
}
