@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.monitorContainer {
  position: absolute;
  z-index: $z-index-monitor;

  border-radius: calc(#{$space} / 2);
  font-family: "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  font-size: 0.75rem;
  transition: box-shadow 0.2s;
  pointer-events: all;
  overflow: hidden;

  @include theme {
    border: 1px solid getVar("primary_border_color");
    background: 1px solid getVar("primary_background_color");
  }
}

.monitor:hover {
  cursor: pointer;
}

.dragging {
  z-index: $z-index-monitor-dragging;
  box-shadow: 3px 3px 5px #888888;
}

.defaultMonitor {
  display: flex;
  flex-direction: column;
  padding: 3px;
}

.label {
  font-weight: bold;
  text-align: center;
  margin: 0 5px;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  text-align: center;
  color: white;
  margin: 0 5px;
  border-radius: calc(#{$space} / 2);
  padding: 0 2px;
  white-space: pre-wrap;
  transform: translateZ(0);
  /* Fixes flickering in Safari */
}

.largeValue {
  min-height: 1.4rem;
  min-width: 3rem;
  padding: 0.1rem 0.25rem;
  text-align: center;
  color: white;
  font-size: 1rem;
  white-space: pre-wrap;
  transform: translateZ(0);
  /* Fixes flickering in Safari */
}

.row {
  display: flex;
  flex-direction: row;
}

.slider {
  width: 100%;
  transform: translateZ(0);
  /* Fixes flickering in Safari */
}

.listMonitor {
  display: flex;
  flex-direction: column;
}

.listHeader {
  background: $ui-white;
  border-bottom: 1px solid $ui-black-transparent;
  text-align: center;
  padding: 3px;
  font-size: 0.75rem;
  font-weight: bold;
  // color: $text-primary;

  width: 100%;

  @include theme {
    color: getVar("text_tertiary_color")
  }
}

.listBody {
  // background: $ui-primary;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: calc(100% - 44px);

  @include theme {
    background-color: getVar("primary_background_color");
  }
}

.listRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
  flex-shrink: 0;
  transform: translateZ(0);
  /* Keep sharp when scaled */
  box-sizing: border-box;
}

.listIndex {
  font-weight: bold;
  // color: $text-primary;
  margin: 0 3px;

  @include theme {
    color: getVar("text_tertiary_color")
  }
}

.listValue {
  box-sizing: border-box;
  min-width: 40px;
  text-align: left;

  margin: 0 3px;
  border-radius: calc(#{$space} / 2);
  border: 1px solid $ui-black-transparent;
  flex-grow: 1;
  height: 22px;

  @include theme {
    color: getVar("invert_text_color")
  }
}

.listFooter {
  background: $ui-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  font-size: 0.75rem;
  font-weight: bold;

  // color: $text-primary;
  @include theme {
    color: getVar("text_tertiary_color")
  }
}

.listEmpty {
  text-align: center;
  width: 100%;
  padding: 5px;
}

.inputWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
}

.valueInner {
  padding: 3px 5px;
  min-height: 22px;
  overflow: hidden;
  /* Don't let long values escape container */
  text-overflow: ellipsis;
  user-select: text;
  /* Allow selecting list values for 2.0 compatibility, only relevant in player */
  white-space: pre;
}

.listInput {
  padding: 3px 5px;
  border: 0;
  background: none;
  // color: $ui-white;

  outline: none;
  font-size: 0.75rem;
  font-family: "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  /* Workaround for Firefox */
  width: 0;
  flex: 1 1 auto;

  @include theme {
    color: getVar("invert_text_color")
  }
}

.removeButton {
  padding: 0;
  padding-right: 5px;
  cursor: pointer;

  // color: $ui-white;
  @include theme {
    color: getVar("invert_text_color")
  }
}

.addButton {
  cursor: pointer;
  margin-right: 3px;
}

.resizeHandle {
  cursor: nwse-resize;
  margin-left: 3px;
}

.footerLength {
  text-align: center;
}

.speechRecordWrapper {
  background: linear-gradient(to left top, #F8CD5A, #F88F33);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  background-color: transparent;

  img {
    -webkit-user-drag: NONE;
    width: 70%;

  }
}

.monitorContainerOther {
  padding: 0;
  border: 0;
  background: none;
}


.waveformContainer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  height: 70px;
  position: relative;

  padding: 3px;

  @include theme {
    background-color: getVar("primary_background_color");
    border: 1px solid getVar("primary_border_color")
  }
}
