@import "css/theme.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  @include theme {
    background-color: getVar("container_bg_color");
  }

  .costumeLibButton {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    @include theme {
      background-color: getVar("primary_color");
    }
  }

  .costumeList {
    width: 112px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;

    @include theme {
      border-right: 1px solid getVar("border_color");
    }

    .item {
      width: 84px;
      height: 84px;

      cursor: pointer;
      transition: box-shadow 0.2s ease-in-out;
      will-change: box-shadow;
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include theme {
        box-shadow: 0 0 1px 1px getVar("body_bg_color");
        background-color: getVar("body_bg_color");
      }

      &:hover {
        @include theme {
          box-shadow: 0 0 1px 1px getVar("primary_color"), 0 0 2px getVar("primary_color") inset;
        }
      }

      .deleteButton {
        width: 20px;
        height: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        position: absolute;
        top: 0;
        right: 0;

        @include theme {
          background-color: getVar("primary_color");
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .number {
        position: absolute;
        top: 2px;
        left: 3px;
        z-index: 3;
        height: 12px;
        font-size: 12px;
        justify-content: start;
      }

      .image {
        width: 84px;
        height: 64px;
        padding: 8px 8px 4px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .name {
        width: 100%;
        height: 20px;
        padding: 0 4px;
        box-sizing: border-box;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }

    &:after {
      content: '';
      display: block;
      height: 70px;
      width: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-box-ordinal-group: 100000000;
      -webkit-order: 99999999;
      -ms-flex-order: 99999999;
      order: 99999999;
    }
  }

  .paint {
    flex-grow: 1;
    height: 100%;
    box-sizing: border-box;
    background-color: white;

    >div {
      box-sizing: border-box;
    }
  }

}
