@import "css/theme.scss";
@import "css/colors.scss";

.container {
  display: flex;
  // gap: 8px;
  justify-content: flex-end;
  .run,.stop {
    height:32px;
  }
  .stop{
    filter: contrast(80%);
    opacity: 0.5
  }

  // .button {
  //   width: 32px;
  //   height: 32px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 50%;

  //   cursor: pointer;
  //   transition: 0.1s;

  //   &:hover {
  //     scale: 1.1;
  //   }
  // }

  // .run {
  //   @extend .button;
  //   @include theme {
  //     background-color: getVar('success_color');
  //     border-color: getVar('success_border_color');
  //     &:hover {
  //       border-color: getVar('success_border_hover_color');
  //       background-color: getVar('success_hover_color');
  //     }
  //     &:active {
  //       background-color: getVar('success_active_color')
  //     }
  //   }
  //   opacity: 0.6;
  // }

  // .stop {
  //   @extend .button;
  //   //opacity: 0.5;
  //   @include theme {
  //     background-color: getVar('error_color');
  //     border-color: getVar('error_border_color');
  //     &:hover {
  //       border-color: getVar('error_border_hover_color');
  //       background-color: getVar('error_hover_color');
  //     }
  //     &:active {
  //       background-color: getVar('error_active_color')
  //     }
  //   }


  //   .stopImg {
  //     width: 12px;
  //     height: 12px;
  //     background-color: white;
  //     border-radius: 1px;
  //   }
  // }
}

.runningContainer {
  @extend .container;
  box-sizing: border-box;
  .run {
    //filter: brightness(115%);
    filter: contrast(90%);
    opacity: 1;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .stop {
    //filter: brightness(130%);
    filter: contrast(130%);
    opacity: 1;
    display: flex;
    align-items: center;
  }
}




.greenFlag {
    width: 32px;
    height: 32px;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 0.25rem;
    user-select: none;
    // user-drag: none;
    cursor: pointer;
}

.greenFlag:hover {
    //background-color: $motion-light-transparent;
  transform: scale(1.2);
  @include theme{
    background-color: getVar("primary_light_transparent");
  }
  
}

.greenFlag.is-active {
    @include theme{
      background-color: getVar("primary_active_color");
    }
    //background-color: $motion-transparent;
}
