.container {
    margin: 18px;
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    overflow: hidden;

    .statusContainer {
        padding: 12px 16px;
        height: 256px;
        width: 100%;
        border-top: 1px solid #E1E1E1;
        box-sizing: border-box;

        .topOperationContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            color: #AAAAAA;

            .operation {
                cursor: pointer;
                display: flex;
                column-gap: 9px;
                align-items: center;

                .operationText {
                    border-bottom: 1px solid #AAAAAA;
                }
            }


        }

        .steps {
            height: calc(100% - 20px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .scanning {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #C6C6C6;
            font-size: 12px;
            text-align: center;
        }

        .timeout {
            height: 100%;
            text-align: center;
            color: #7A7A7A;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .timeoutText {
                display: grid;
                justify-items: flex-start;
                grid-template-columns: 200px auto;
                grid-template-rows: auto auto auto;
                grid-template-areas:
                    "f1 . "
                    "f2 f3 "
                    ".  f4";

                p:nth-of-type(1) {
                    grid-area: f1;
                    text-align: left;
                }

                p:nth-of-type(2) {
                    grid-area: f2;
                    text-align: left;
                }

                p:nth-of-type(3) {
                    grid-area: f3;
                    text-align: left;
                }

                p:nth-of-type(4) {
                    grid-area: f4;
                    text-align: left;
                }
            }
        }

        .connecting,
        .connected,
        .connectError {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .center {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1px;

                .imgContainer {
                    position: relative;

                    .img {
                        width: 133px;
                        height: 133px;
                    }

                    .radarSpinImg {
                        width: 133px;
                        height: 133px;
                        animation: spin 4s linear infinite;
                    }

                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .text {
                    margin-top: 26px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #628DFF;
                }

                .textError {
                    color: #FF8080
                }
            }
        }

    }

    .noData {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .selectedRowIcon {
        color: #6c6c6c !important;
    }
}

.stepContainer {
    box-sizing: border-box;
    height: 75px;
    width: 23px;
    display: flex;
    flex-direction: column;
    background: rgba(149, 202, 240, 0.2);
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 13px 0;


    .active {
        background-color: #628DFF;
        border: 0.5px solid #628DFF;
    }

    >div {

        border-radius: 50%;
        font-size: 8px;
        font-weight: bold;
        background: #D8D8D8;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
        position: relative;

        &:first-child {
            &::after {
                content: "";
                position: absolute;
                bottom: -25px;
                width: 1px;
                height: 25px;
                border-left: 1px dashed #628DFF;
                border-radius: 50%;
            }
        }
    }

}

.connectBtn {
    height: 26px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #AAAAAA;
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected {
        border: 1px solid #A26A00;
        color: #A26A00;
    }
}


@keyframes spin {
    100% {
        transform: rotate(360deg);
        transform-origin: center;
    }
}
