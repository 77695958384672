@import "css/units.scss";
@import "css/colors.scss";
@import "css/theme";


@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spriteInfo {
    padding: 0.75rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: white;
    color: $text-primary;
    border-top-left-radius: $space;
    border-top-right-radius: $space;
    border-bottom: 1px solid $ui-black-transparent;

    * {
        box-sizing: border-box;
    }
}

.row {
    display: flex;
    justify-content: space-between;
}

.rowPrimary {
    margin-bottom: 0.5rem;
}

.label {
    opacity: 0.8;
}

.group {
    display: inline-flex;
    flex-direction: row;
    /* makes columns, for each label/form group */
    align-items: center;
}

.column {
    display: inline-flex;
    flex-direction: column;
    /* put label above input */
    align-items: flex-start;
}

.column span {
    margin-bottom: .25rem;
}

.iconWrapper {
    display: inline-block;
    width: 18px;
    height: 18px;
    outline: none;
    user-select: none;
}

.icon {
    width: 100%;
    height: 100%;

}

/* @todo: refactor radio divs to input */
.radioWrapper {
    white-space: nowrap;
    display: flex;
    /* make sure visibilty buttons don't wrap */
}

.radio {
    filter: saturate(0);
    cursor: pointer;
    @include flex-center();
    padding: 0;
    height: 34px;
    width: 34px;
    box-sizing: border-box;
}

.radio.isActive {
    filter: none;

    @include theme {
        border-color: getVar("primary_color");
        background-color: getVar("primary_background_color");
    }
}

.radio.isDisabled {
    cursor: default;
}

.radioFirst {
    border: 1px solid $ui-black-transparent;
}

[dir="ltr"] .radioFirst {
    border-top-left-radius: $form-radius;
    border-bottom-left-radius: $form-radius;
}

[dir="rtl"] .radioFirst {
    border-top-right-radius: $form-radius;
    border-bottom-right-radius: $form-radius;
}

.radioFirst:focus {
    box-shadow: inset 0 0 0 -2px $ui-black-transparent;

    @include theme {
        border-color: getVar("primary_color");
    }
}

.radioLast {
    border-bottom: 1px solid $ui-black-transparent;
    border-top: 1px solid $ui-black-transparent;
}

[dir="ltr"] .radioLast {
    border-right: 1px solid $ui-black-transparent;
    border-left: 1px solid $ui-black-transparent;
    border-top-right-radius: $form-radius;
    border-bottom-right-radius: $form-radius;
}

[dir="rtl"] .radioLast {
    border-left: 1px solid $ui-black-transparent;
    border-right: 1px solid $ui-black-transparent;
    border-top-left-radius: $form-radius;
    border-bottom-left-radius: $form-radius;
}

.radioLast:focus {
    box-shadow: inset 0 0 0 -2px $ui-black-transparent;

    @include theme {
        border-color: getVar("primary_color");
    }
}

.icon {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.rotationSelect {
    width: 100%;
    height: 1.85rem;
    border: 1px solid $ui-black-transparent;
    user-select: none;
    outline: none;
}

.rotationSelect:focus {
    //border-color: $motion-primary;
    box-shadow: inset 0 0 0 1px $ui-black-transparent;

    @include theme {
        border-color: getVar("primary_color");
    }
}

.largerInput input {
    width: 4rem;
}

.spriteInput {
    width: 8rem;
}
